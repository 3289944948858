define("discourse/plugins/waze-session/discourse/initializers/waze-session-initializer", ["exports", "discourse/lib/plugin-api"], function (_exports, n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var e = {
      d: (n, t) => {
        for (var o in t) e.o(t, o) && !e.o(n, o) && Object.defineProperty(n, o, {
          enumerable: !0,
          get: t[o]
        });
      },
      o: (n, e) => Object.prototype.hasOwnProperty.call(n, e)
    },
    t = {};
  e.d(t, {
    A: () => l
  });
  const o = (i = {
    withPluginApi: () => n.withPluginApi
  }, r = {}, e.d(r, i), r);
  var i,
    r,
    s = function (n, e, t, o) {
      return new (t || (t = Promise))(function (i, r) {
        function s(n) {
          try {
            l(o.next(n));
          } catch (n) {
            r(n);
          }
        }
        function c(n) {
          try {
            l(o.throw(n));
          } catch (n) {
            r(n);
          }
        }
        function l(n) {
          var e;
          n.done ? i(n.value) : (e = n.value, e instanceof t ? e : new t(function (n) {
            n(e);
          })).then(s, c);
        }
        l((o = o.apply(n, e || [])).next());
      });
    };
  function c(n, e, t) {
    return s(this, void 0, void 0, function* () {
      var o;
      const i = yield function () {
          return s(this, void 0, void 0, function* () {
            try {
              const n = yield fetch("/login/get");
              return yield n.json();
            } catch (n) {
              console.log("Failed to check session", n);
            }
            return null;
          });
        }(),
        r = !0 === (null === (o = null == i ? void 0 : i.reply) || void 0 === o ? void 0 : o.login),
        c = !!(null == n ? void 0 : n.username);
      !c && r ? e() : c && !r ? t() : c && r && i.reply.user_id.toString() !== n.external_id.toString() && t().then(e);
    });
  }
  const l = {
    name: "waze-session",
    initialize() {
      (0, o.withPluginApi)("1.1.0", n => {
        if (!n.container.lookup("service:site-settings").waze_session_enabled) return;
        const e = () => n.container.lookup("route:application").send("showLogin"),
          t = () => {
            var e;
            return null === (e = n.getCurrentUser()) || void 0 === e ? void 0 : e.destroySession();
          };
        c(n.getCurrentUser(), e, t).catch(console.error), n.onPageChange(() => c(n.getCurrentUser(), e, t).catch(console.error));
      });
    }
  };
  var u = _exports.default = t.A;
});